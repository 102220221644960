import "./App.css";
import Header from "./components/Header";
import Item from "./components/Item";

import ModelS from "./assets/Desktop-ModelS.jpeg";
import Model3 from "./assets/Desktop-Model3.jpeg";
import ModelX from "./assets/Desktop-ModelX.jpeg";
import ModelY from "./assets/Desktop-ModelY.jpeg";
import SolarPanels from "./assets/Desktop-SolarPanels.jpeg";
import SolarRoof from "./assets/Desktop-SolarRoof.jpeg";

function App() {
  return (
    <div className="App">
      <Header />
      <div className="app_itemsContainer">
        <Item
          title="Model S"
          desc="Buchen Sie eine kontaktlose Probefahrt"
          descLink="https://www.tesla.com/de_de/drive"
          backgroundImg={ModelS}
          leftBtnTxt="Konfiguration"
          leftBtnLink="https://www.tesla.com/de_de/models/design"
          rightBtnTxt="Verfügbare Neuwagen"
          rightBtnLink="https://www.tesla.com/de_de/inventory/new/ms"
          twoButtons="true"
          id="S"
          first
        />
        <Item
          title="Model 3"
          desc="Jedes Model 3 bestellen mit 9.000€ Umweltbonus"
          descLink="https://www.tesla.com/de_de/model3/design"
          backgroundImg={Model3}
          leftBtnTxt="Konfiguration"
          leftBtnLink="https://www.tesla.com/de_de/model3/design"
          rightBtnTxt="Verfügbare Neuwagen"
          rightBtnLink="https://www.tesla.com/de_de/inventory/new/m3"
          id="3"
          twoButtons="true"
        />
        <Item
          title="Model X"
          desc="Buchen Sie eine kontaktlose Probefahrt"
          descLink="https://www.tesla.com/de_de/drive"
          backgroundImg={ModelX}
          leftBtnTxt="Konfiguration"
          leftBtnLink="https://www.tesla.com/de_de/modelx/design"
          rightBtnTxt="Verfügbare Neuwagen"
          rightBtnLink="https://www.tesla.com/de_de/inventory/new/mx"
          id="X"
          twoButtons="true"
        />
        <Item
          title="Model Y"
          desc=""
          descLink=""
          backgroundImg={ModelY}
          leftBtnTxt="Mehr erfahren"
          leftBtnLink="https://www.tesla.com/de_de/modely"
          rightBtnTxt=""
          id="Y"
          rightBtnLink=""
          twoButtons=""
        />
        <Item
          title="Solarkollektoren und Powerwall"
          desc="Solarstrom für alles"
          descLink=""
          backgroundImg={SolarPanels}
          leftBtnTxt="Entdecken"
          leftBtnLink="https://www.tesla.com/de_de/powerwall"
          rightBtnTxt=""
          id="Solar"
          rightBtnLink=""
          twoButtons=""
        />
        <Item
          title="Zubehör"
          desc=""
          descLink="https://www.tesla.com/de_de/model3/design"
          backgroundImg={SolarRoof}
          leftBtnTxt="Jetzt einkaufen"
          leftBtnLink="https://www.tesla.com/de_de/shop"
          rightBtnTxt=""
          id="Zubehör"
          rightBtnLink=""
          twoButtons=""
        />
      </div>
    </div>
  );
}

export default App;
